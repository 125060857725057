/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { Observable, catchError, throwError, switchMap } from 'rxjs';
import { ErrorToastService } from 'src/app/services/error-toast.service';
import { AuthService } from '../../services/auth-service.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private errorToastService: ErrorToastService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authRequest = request.clone();
    // hack
    if (request.url.indexOf('usermanagement/registration/refresh-token') === -1) {
        authRequest = request.clone({
        headers: request.headers.append(
          'authorization',
          this.authService.getAccessToken()
        )
      });
    }

    return next.handle(authRequest).pipe(
      catchError(error => {
        switch (error.status) {
          case 0:
            break;
          case 401: // not authorized
            return this.handle401Error(request, next);
          case 200:
            this.errorToastService.sendError(error.status);
            break;
          case 422:
            this.errorToastService.sendError(error.status);
            this.router.navigate(['smartseeds-integration']);
            break;
          default:
            // this.errorToastService.sendError(error.status);
            break;
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        // Повторно выполняем запрос с обновленным токеном
        const tokenizedRequest = request.clone({
          headers: request.headers.append(
            'authorization',
            this.authService.getAccessToken()
          )
        });
        return next.handle(tokenizedRequest);
      }),
      catchError(error => {
        // Если обновление токена не удалось, перенаправляем на страницу логина
        this.authService.logout();
        // Редирект на страницу логина
        return throwError(() => error);
      })
    );
  }
}
