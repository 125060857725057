import { Component } from '@angular/core';
import * as _ from 'lodash';
import { HeaderDto } from '../../../dto/header.dto';
import { OrdersListDto } from '../../../dto/orders-list.dto';
import { Status } from '../../../dto/status.enum';

@Component({
  selector: 'app-archived-orders',
  templateUrl: './archived-orders.component.html',
  styleUrls: ['./archived-orders.component.scss']
})
export class ArchivedOrdersComponent {
  headerDataValues: HeaderDto = {
    statuses: [Status.COMPLETED, Status.CLOSED],
    totalCount: 0,
    searchTerms: [],
    title: 'Архив',
    tabRoute: 'archived-requests'
  };

  ordersListDataValues: OrdersListDto = {
    statuses: [],
    searchTerms: [],
  };

  getTotalCount(totalCount: number) {
    this.headerDataValues = {
      ...this.headerDataValues,
      totalCount
    };
  }

  switchArchiveTab(event: Status) {
    const newListDataValues = _.cloneDeep(this.ordersListDataValues);
    newListDataValues.statuses = [event];
    this.ordersListDataValues = newListDataValues;
  }
}
